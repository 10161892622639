export const RETURN_URL_QUERY_PARAM = 'returnUrl';
export const DEFAULT_RETURN_URL = '/';
export const LOGIN_REDIRECT_DELAY: number = 2000;

export const REMOVE_CLASS_DELAY = 4000;
export const LIST_COLUMNS_SELECTION_CHANGE_DEBOUNCE_TIME: number = 100;
export const LIST_COLUMNS_FILTER_CHANGE_DEBOUNCE_TIME: number = 400;
export const LIST_COLUMNS_FILTER_CHANGE_DEBOUNCE_TIME_SMALL: number = 200;
/** A very small debounce time (~2 browser ticks) to smooth out RXJS merge and prevent multiple calls */
export const DEBOUNCE_RXJS_MERGE: number = 32;
export const DEBOUNCE_CLICK_SMALL: number = 50;
export const DEBOUNCE_INPUT_CHANGE_SMALL: number = 400;
export const DEBOUNCE_INPUT_CHANGE_TIME_SMALL: number = 200;
export const DROPDOWN_FILTER_RESULTS_LIMIT: number = 10;
// Unit Defect List
export const DEFECT_ELEM_HEIGHT = 35;
export const SCROLL_TO_ELEM_DELAY = 50;

export const UPLOAD_PAYLOAD_MAX_SIZE: number = 4 * 1024 * 1024 * 1024; // 4 GB

export const WEEK_SELECTOR_NUMBER_OF_WEEKS = 9;

export const MAX_FRACTION_DIGITS: number = 2;

export const HOURS_MIN_VALUE: number = -999999.99;
export const HOURS_MAX_VALUE: number = 999999.99;
export const USER_WORK_HOURS_PER_WEEK_MIN_VALUE: number = 0;
export const LEVEL_HOURS_MIN_VALUE: number = 0;
export const LEVEL_COST_MIN_VALUE: number = 0;
export const LEVEL_HOURLY_RATE_MIN_VALUE: number = 0;
export const WORK_PACKAGE_ESTIMATED_HOURS_MIN_VALUE: number = 0.01;
export const WORK_PACKAGE_RESPONSIBLE_PLANNED_HOURS_MIN_VALUE: number = 0;
export const WORK_PACKAGE_RESPONSIBLE_PLANNED_HOURS_AFTER_FORECAST_MIN_VALUE: number = 0.01;
export const UNIT_CONTRACT_HOURS_PER_WEEK_MIN_VALUE: number = 0;

// === Table / List Configuration ===
// ----------------------------------------------------------------------------
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
export const DEFAULT_PAGE_SIZE = 25;

// Project list
export const PROJECT_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const PROJECT_LIST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

// Unit list
export const UNIT_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const UNIT_LIST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

// Non-human resource list
export const NON_HUMAN_RESOURCE_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const NON_HUMAN_RESOURCE_LIST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

// User list
export const USER_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const USER_LIST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

// Work Package list
export const WORK_PACKAGE_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const WORK_PACKAGE_LIST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export const WORK_PACKAGE_LIST_EXPORT_TIMEOUT = 5 * 60 * 1000; //5 minutes

// Work Package Question list
export const WORK_PACKAGE_QUESTION_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const WORK_PACKAGE_QUESTION_LIST_DEFAULT_PAGE_SIZE = 10;

// Questions list
export const QUESTIONS_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const QUESTIONS_LIST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

// Resource Planning
export const USER_RESOURCE_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const USER_RESOURCE_LIST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export const NON_HUMAN_RESOURCE_PLANNING_LIST_PAGE_SIZE_OPTIONS = [...DEFAULT_PAGE_SIZE_OPTIONS];
export const NON_HUMAN_RESOURCE_PLANNING_LIST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

export const WORK_PACKAGE_ACTIVITIES_DEFAULT_PAGE_SIZE = 10;
// ----------------------------------------------------------------------------

// === Form Validation ===
// ----------------------------------------------------------------------------

// --- TimeTracking / Planning --
export const WEEKLY_PLANNED_HOURS_MIN: number = 0;
export const WEEKLY_PLANNED_HOURS_MAX: number = 999999.99;
export const WEEKLY_PLANNED_HOURS_STEP: number = 0.1;

export const TIME_TRACKING_HOURS_MIN: number = 0;
export const TIME_TRACKING_HOURS_MAX: number = 24;
export const TIME_TRACKING_HOURS_STEP: number = 0.1;

// ----------------------------------------------------------------------------

export const HOLIDAY_SEPARATOR = ';';
